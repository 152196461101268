import googleAdsImage from '../../../assets/images/google-ads.png';
import techSolutionsImage from '../../../assets/images/tech-solutions.png';
import websiteImage from '../../../assets/images/website.png';
import './About.css';

export const About = () => (
	<section className="about" id="about">
		<h1>Quem Somos</h1>
		<div className="row">
			<div className="about-column">
				<img alt="Google Ads" src={googleAdsImage} />
				<div className="layer">
					<h3>Google Ads</h3>
				</div>
			</div>

			<div className="about-column">
				<img alt="Website" src={websiteImage} />
				<div className="layer">
					<h3>Criação de Websites</h3>
				</div>
			</div>

			<div className="about-column">
				<img alt="IT" src={techSolutionsImage} />
				<div className="layer">
					<h3>Consultoria em TI</h3>
				</div>
			</div>
		</div>
		<p>
			A <b>Yotec</b> é uma empresa de tecnologia que oferece soluções de hospedagem de sites,
			registro de domínios, e-mails profissionais, servidores dedicados,
			serviços de segurança e suporte técnico para clientes de diversos segmentos.
			A empresa utiliza tecnologias de ponta para garantir alta performance, segurança e
			estabilidade em seus serviços, como infraestrutura da AWS, certificados SSL, backups
			automatizados, e ferramentas de proteção contra ataques DDoS.
		</p>
		<p>
			A empresa também possui um time técnico altamente qualificado e especializado
			em diversas áreas de TI, como desenvolvimento de software, banco de dados, segurança
			e suporte técnico, garantindo um atendimento personalizado e eficiente aos clientes.
			Além disso, a Yotec investe constantemente em novas tecnologias e soluções inovadoras
			para oferecer um serviço cada vez mais completo e moderno aos seus clientes.
		</p>
	</section>
);