import { Box } from "@mui/material";
import { Link } from 'react-router-dom';
import logoImage from '../../assets/images/logo.png';

export const Home = () => (
    <Box id="header" className="header">
        <Box className="header-box">
            <img src={logoImage} alt="Yotec logo" />
            <h1>Quer diminuir seus custos e agregar valor com inovação e tecnologia?</h1>
            <p id="description">A melhor alternativa para quem busca o melhor custo/benefício está aqui, na <strong>Yotec: Tecnologia e Inovação</strong>!</p>
            <Link to="/services" className="header-button">Conheça os nossos serviços</Link>
        </Box>
    </Box>
);