import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Portfolio.css";
import { Box, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import alavancaSolucoes from "./../../../assets/images/portfolio/alavancasolucoes.png";
import arqueriaFloripa from "./../../../assets/images/portfolio/arqueriafloripa.png";
import emPessoa from "./../../../assets/images/portfolio/empessoa.png";
import nutriAriana from "./../../../assets/images/portfolio/nutriariana.png";
import topMantas from "./../../../assets/images/portfolio/topmantas.png";

const Portfolio: React.FC = () => {
  const images = [
    nutriAriana,
    emPessoa,
    topMantas,
    alavancaSolucoes,
    arqueriaFloripa,
  ];

  const descriptions = [
    "www.nutriariana.com.br",
    "www.empessoa.com",
    "www.topmantasimpermeabilizacao.com.br",
    "www.alavancacred.com.br",
    "www.arqueriafloripa.com.br",
  ];

  return (
    <section className="portfolio">
      <h1>Nossos Trabalhos</h1>
      <Box>
        <Carousel
          autoPlay
          infiniteLoop
          interval={3000}
          showThumbs={false}
          showIndicators={true}
          showStatus={false}
          centerMode
          centerSlidePercentage={20}
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
        >
          {descriptions.map((description, index) => (
            <div key={index}>
              <Box className="carousel-box">
                <img src={images[index]} alt={`Slide ${index + 1}`} />
                <Typography variant="h6">
                  <a
                    href={`http://${description}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {description}
                  </a>
                </Typography>
              </Box>
            </div>
          ))}
        </Carousel>
      </Box>
    </section>
  );
};

export default Portfolio;
