import { Box } from "@mui/material";
import artsynImage from '../../../assets/images/artsyn.png';
import kairosImage from '../../../assets/images/kairos.png';
import synmarcasImage from '../../../assets/images/synmarcas.png';
import './Partners.css';

export const Partners = () => (
	<Box>
		<section className="partners" id="partners">
			<h1>Nossos Parceiros</h1>
			<p>A <b>Yotec</b> investe em boas parcerias com as quais possa compartilhar ideias e negócios voltados a garantir a segurança e a divulgação de seus clientes no mercado.</p>

			<div className="row">
				<div className="partners-column">
					<img src={synmarcasImage} alt="Syn Marcas" />
					<h3>Syn Marcas</h3>
					<p>
						A Syn Marcas é o fruto da união de profissionais altamente qualificados que, através da soma de conhecimentos, oferece aos seus parceiros e clientes as soluções necessárias para atender o cada vez mais exigente mercado digital, promovendo a segurança e a divulgação da marca nas redes sociais.
					</p>
				</div>

				<div className="partners-column">
					<img src={kairosImage} alt="Kairos" />
					<h3>Kairós Marcas e Patentes</h3>
					<p>
						A Kairós Marcas e Patentes é especializada em proteção de uso e registro de marcas, patentes, software, desenho industrial e indicação geográfica junto ao Instituto Nacional da Propriedade Industrial - INPI.
					</p>
				</div>

				<div className="partners-column">
					<img src={artsynImage} alt="ArtSyn" />
					<h3>ArtSyn</h3>
					<p>
						A ArtSyn é especializada em Design, desenvolvendo projetos de identidade visual, web design e design de mídias sociais.
					</p>
				</div>

			</div>
		</section>
		{/* TODO: Add testimonials section */}
		{/* <section className="testimonials">
			<h1>O que os nossos clientes dizem</h1>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

			<div className="row">
				<div className="testimonial-column">
					<img src={testUserA} />
					<div>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dapibus rhoncus nibh, et ultrices velit pellentesque vel. Praesent non diam eget leo finibus consequat bibendum sed magna. Vivamus tellus enim, euismod non egestas at, luctus quis est. Mauris vehicula imperdiet diam, sit amet lobortis massa rutrum tempus. Integer viverra nunc vel lectus condimentum pulvinar.
						</p>
						<h3>Christiane Berkley</h3>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star"></i>
						<i className="fa-regular fa-star"></i>
					</div>
				</div>

				<div className="testimonial-column">
					<img src={testUserB} />
					<div>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dapibus rhoncus nibh, et ultrices velit pellentesque vel. Praesent non diam eget leo finibus consequat bibendum sed magna. Vivamus tellus enim, euismod non egestas at, luctus quis est. Mauris vehicula imperdiet diam, sit amet lobortis massa rutrum tempus. Integer viverra nunc vel lectus condimentum pulvinar.
						</p>
						<h3>David Byer</h3>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star"></i>
						<i className="fa-solid fa-star-half-stroke"></i>
					</div>
				</div>
			</div>
		</section> */}
	</Box>
);