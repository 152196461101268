import './Services.css'

export const Services = () => (
    <section className="services" id="services">
        <h1>Nossos Serviços</h1>
        <p>Somos uma empresa que além de posicionamento no Google, é especializada em atender os requisitos tecnológicos que venham agregar mais valor para seu negócio e ter maior visibilidade na internet.</p>

        <div className="row">
            <div className="services-column">
                <h1>Google Ads</h1>
                <p>
                    <ul className="itens-list">
                        <li>O nome da sua empresa no Google</li>
                        <li>Meu Negócio no Google</li>
                        <li>Apareça online</li>
                    </ul>
                </p>
            </div>

            <div className="services-column">
                <h1>Websites</h1>
                <p>
                    <ul className="itens-list">
                        <li>Tenha a sua página acessível na internet</li>
                        <li>E-commerce</li>
                    </ul>
                </p>
            </div>

            <div className="services-column">
                <h1>Consultoria em TI</h1>
                <p>
                    <ul className="itens-list">
                        <li>Suporte AWS Cloud</li>
                        <li>Migração de Hospedagem</li>
                        <li>Outros serviços em TI</li>
                    </ul>
                </p>
            </div>

        </div>
    </section>
);