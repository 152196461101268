import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import "./Contact.css";
import qrCodeImage from "./../../../assets/images/qr-code.png";

export const Contact = () => (
  <Box>
    <section className="call-to-action">
      <h1>
        Entre em contato conosco para saber mais
        <br />
        <h6>Faça o seu orçamento sem compromisso</h6>
      </h1>
      <div className="contact">
        <img src={qrCodeImage} alt="QR Code" className="qr-code" />
        <a href="https://wa.me/5548991132062" className="contact-button">
          Fale Conosco
        </a>
      </div>
    </section>

    <section className="footer">
      <h4>Sobre nós</h4>
      <div className="icons">
        <a
          href="https://www.facebook.com/Yotec.Tecnologia/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-brands" icon={faFacebookF} />
        </a>
        <a
          href="https://www.twitter.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-brands" icon={faTwitter} />
        </a>
        <a
          href="https://www.instagram.com/yotec.tecnologia"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-brands" icon={faInstagram} />
        </a>
        <a
          href="https://www.linkedin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-brands" icon={faLinkedinIn} />
        </a>
        <a
          href="https://wa.me/5548991132062"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-brands" icon={faWhatsapp} />
        </a>
      </div>
      <p>Rua Nereu Ramos, 90 - Centro, Biguaçu - SC, 88160-116</p>
      <p>Made by Yotec</p>
    </section>
  </Box>
);
