import { Box, ThemeProvider, Typography } from '@mui/material';
import { Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import { Header } from './components/Header';
import { appTheme } from './config/theme';
import { About } from './features/pages/About/About';
import { Contact } from './features/pages/Contact/Contact';
import { Home } from './features/pages/Home';
import { Partners } from './features/pages/Partners/Partners';
import { Services } from './features/pages/Services/Services';
import WhatsAppButton from './components/WhatsAppButton';
import TagManager from 'react-gtm-module';
import React, { useEffect } from 'react';
import Portfolio from './features/pages/Portfolio/Portfolio';

const tagManagerArgs = {
  gtmId: 'GTM-TVCB9BW',
};

TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          page: location.pathname,
          location: window.location.href,
        },
      });
    };

    trackPageView(); // Track the initial page view

    return () => {
      trackPageView(); // Track subsequent page views
    };
  }, [location]);

  return (
    <ThemeProvider theme={appTheme}>
      <Box
        component="main"
        sx={{
          height: "100%",
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
      >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="*" element={<Typography variant="h4">Page not found</Typography>} />
        </Routes>
        <WhatsAppButton />
      </Box>
    </ThemeProvider>
  );
};

export default App;