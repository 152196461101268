import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import logoMenuImage from "../assets/images/logo-menu.png";

export const VisibleMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const showMenu = () => {
    setMenuVisible(true);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };
};

export default Header;

export function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="navbar">
      <img src={logoMenuImage} alt="Yotec logo (menu)" />
      <div
        className="nav-links"
        id="navLinks"
        style={{ right: showMenu ? "0" : "-200px" }}
      >
        <i className="fa-solid">
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowMenu(false)} />
        </i>
        <ul>
          <li>
            <Link to="/" onClick={() => setShowMenu(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setShowMenu(false)}>
              Quem Somos
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => setShowMenu(false)}>
              Serviços
            </Link>
          </li>
          <li>
            <Link to="/partners" onClick={() => setShowMenu(false)}>
              Parceiros
            </Link>
          </li>
          <li>
            <Link to="/portfolio" onClick={() => setShowMenu(false)}>
              Portifólio
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setShowMenu(false)}>
              Contato
            </Link>
          </li>
        </ul>
      </div>
      <i className="fa-solid">
        <FontAwesomeIcon icon={faBars} onClick={() => setShowMenu(true)} />
      </i>
    </nav>
  );
}
